import { boundMethod } from 'autobind-decorator';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import { LikeButton } from 'components/common/LikeButton';
import { Menu } from './Menu';
import { INavBarProps, IState } from './NavBarModels';
import { UserMenu } from './UserMenu';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from 'components/Routes/RoutePaths';

import winterLogo from 'assets/images/logos/logo-165x49-noBackground-winter.png';
import logo from 'assets/images/logos/logo-165x49.png';
import './NavBar.scss';

class NavBarComponent extends React.PureComponent<INavBarProps & WithTranslation, IState> {
    private navBar = React.createRef<HTMLDivElement>();

    constructor(props: INavBarProps & WithTranslation) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    public componentDidMount() {
        const { onInit } = this.props;
        onInit();
        window.addEventListener('click', this.handleClick, false);
        window.addEventListener('touchend', this.handleClick, false);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.handleClick, false);
        window.removeEventListener('touchend', this.handleClick, false);
    }

    public render() {
        const { isAuthenticated, isGuest } = this.props;
        return (
            <div ref={this.navBar}>
                <Navbar id="site-header" className="site-header navbar navbar-expand-md navbar-dark bg-dark fixed-top">
                    <NavLink to={RoutePaths.Maps.Map.url()} className="mr-2 ml-2 navbar-brand">
                        {this.renderLogo()}
                    </NavLink>
                    <NavbarToggler onClick={this.toggle} className="mr-2" />
                    <Collapse isOpen={this.state.isOpen} navbar={true} id="main-navbar">
                        <Menu isAuthenticated={isAuthenticated} isGuest={isGuest} />
                        <div className="d-flex flex-fill justify-content-center align-item-center">
                            <LikeButton />
                        </div>
                        <UserMenu />
                    </Collapse>
                </Navbar>
            </div>
        );
    }

    private renderLogo() {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const currentDate = new Date().getDate();
        const isWinter = currentMonth === 11 && currentDate >= 20 || currentMonth === 0 || currentMonth === 1 && currentDate < 20;
        return isWinter ?
            (
                <img
                    src={winterLogo}
                    alt={`Mes hôtes Winter ${currentYear}`}
                />
            ) :
            (
                <img src={logo} alt="Mes hôtes" />
            );
    }

    @boundMethod
    private toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    @boundMethod
    private handleClick(e: MouseEvent | TouchEvent) {
        if (!this.navBar || !this.navBar.current) {
            return;
        }

        if (e.target) {
            if (!this.navBar.current.contains((e.target as Element))) {
                this.setState({
                    isOpen: false,
                });
            }
        }
    }
}

export const NavBar = withTranslation()(NavBarComponent);
