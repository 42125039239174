import i18n from 'i18next';
import { findResxKey } from './ResxService';

export const convertToCountryCode = (country?: string, lang?: string) => {
    if (!country) {
        return undefined;
    }

    const resx = (i18n.getDataByLanguage(lang?.toLowerCase() || i18n.language) as Record<string, Record<string, string>>);
    const enResx = (i18n.getDataByLanguage('en') as Record<string, Record<string, string>>);
    const countriesResx: { [key: string]: string; } = resx.Countries;
    const defaultCountriesResx: { [key: string]: string; } = enResx.Countries;
    const countryCode = country && (findResxKey(countriesResx, country)
        || findResxKey(defaultCountriesResx, country));

    return countryCode;
};