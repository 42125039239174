import { push } from 'connected-react-router';
import i18n from 'i18next';
import { noop } from 'lodash-es';
import moment from 'moment';
import { connect } from 'react-redux';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import { AppThunkDispatch, ApplicationState } from 'store';
import { changeLanguage, getAppContext } from 'store/appContext/thunk';
import { Selectors } from 'store/normalizr/selectors';
import { Language } from './Language';
import { IDispatchProps, IMatchParms, IProps } from './LanguageModels';
import { Language as LanguageModel } from 'services/api/ApiClient';
import { convertToCountryCode } from 'services/CountryService';
import { findResxKey } from 'services/ResxService';

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps): IProps => {
    const appContext = Selectors.getAppContext(state.appContext.appContextId, state);
    const supportedLanguages = appContext?.supportedLanguages ? appContext.supportedLanguages : [];
    const match = matchPath<IMatchParms>(ownProps.location.pathname, {
        path: '/:lang([a-zA-Z]{2})',
    });
    const lang = match?.params.lang.toLowerCase() || i18n.language;
    const requestedLanguage = lang ? supportedLanguages.find((l) => l.twoLetterISOLanguageName?.toLowerCase() === lang) : undefined;
    return {
        isAuthenticated: !!state.oidc.user || !!appContext?.loggedUser,
        language: appContext?.language ? appContext.language : null,
        requestedLanguage,
        supportedLanguages,
    };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch, ownProps: RouteComponentProps): IDispatchProps => {
    return {
        onInit: (requestedLanguage) => {
            dispatch(getAppContext())
                .then((data) => {
                    if (data) {
                        const currentLanguage = data.language ? data.language.twoLetterISOLanguageName?.toLowerCase() : undefined;
                        const urlLanguage = requestedLanguage ? requestedLanguage.twoLetterISOLanguageName?.toLowerCase() : undefined;

                        if (urlLanguage) {
                            moment.locale(urlLanguage);
                            i18n.changeLanguage(urlLanguage).catch(noop);
                        }

                        if (currentLanguage !== urlLanguage) {
                            if (requestedLanguage && requestedLanguage.twoLetterISOLanguageName) {
                                moment.locale(requestedLanguage.twoLetterISOLanguageName.toLowerCase());
                                i18n.changeLanguage(requestedLanguage.twoLetterISOLanguageName.toLowerCase());
                                if (data.loggedUser) {
                                    dispatch(changeLanguage(requestedLanguage)).catch(noop);
                                }
                            }
                        }
                    }
                })
                .catch(noop);
        },
        onChangeLanguage: (language, isAuthenticated) => {
            if (language.twoLetterISOLanguageName) {
                moment.locale(language.twoLetterISOLanguageName.toLowerCase());
                i18n.changeLanguage(language.twoLetterISOLanguageName.toLowerCase());
            }

            if (isAuthenticated) {
                dispatch(changeLanguage(language))
                    .then(() => {
                        changeRoute(ownProps.location.pathname, language, dispatch);
                    })
                    .catch(noop);
            } else {
                changeRoute(ownProps.location.pathname, language, dispatch);
            }
        },
    };
};

const changeRoute = (pathname: string, language: LanguageModel, dispatch: AppThunkDispatch) => {
    const match = matchPath<IMatchParms>(pathname, {
        path: '/:lang([a-zA-Z]{2})/:route*',
    });
    if (match) {
        const urlLanguage = match.params.lang.toLowerCase();
        let newPathname = `/${language.twoLetterISOLanguageName?.toLowerCase()}`;

        if (match.params.route) {
            const resx = (i18n.getDataByLanguage(urlLanguage) as Record<string, Record<string, string>>);
            const enResx = (i18n.getDataByLanguage('en') as Record<string, Record<string, string>>);
            const routingResx: { [key: string]: string; } = resx.Routing;
            const defaultRoutingResx: { [key: string]: string; } = enResx.Routing;

            const routeFragments = match.params.route.split('/');
            const newRouteFragments = routeFragments.map((routeFragment) => {
                const resxKey = findResxKey(routingResx, routeFragment)
                    || findResxKey(defaultRoutingResx, routeFragment);
                if (resxKey) {
                    return resxKey ? i18n.t(`Routing:${resxKey}`) : routeFragment;
                }
                const countryCode = convertToCountryCode(routeFragment, urlLanguage);
                return countryCode ? i18n.t(`Countries:${countryCode}`) : routeFragment;
            });

            if (newRouteFragments.length) {
                newPathname += `/${newRouteFragments.join('/')}`;
            }
        }

        dispatch(push(newPathname.toLowerCase()));
    }
    else {
        dispatch(push(`/${language.twoLetterISOLanguageName?.toLowerCase()}`));
    }
};

export const LanguageContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Language));
