
import { RoutePaths } from 'components/Routes/RoutePaths';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface IProps {
    isAuthenticated: boolean;
    isGuest: boolean;
}

class MenuComponent extends React.PureComponent<IProps & WithTranslation> {
    public render() {
        const { t } = this.props;
        return (
            <ul className="nav nav-pills">
                {this.renderDemoMenu()}
                {this.renderUserMenu()}

                <li className="nav-item">
                    <NavLink to={RoutePaths.Maps.Map.url()} className="nav-link">
                        {t('Sitemap:SearchDomain')}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <a href={RoutePaths.Blog.url()} className="nav-link">
                        {t('Sitemap:Blog')}
                    </a>
                </li>
            </ul>
        );
    }

    private renderDemoMenu() {
        const { isAuthenticated, t } = this.props;
        return !isAuthenticated ? (
            <li className="nav-item">
                <a
                    href={RoutePaths.Events.List.url(undefined, undefined, t('Routing:Demo'))}
                    className="nav-link"
                >
                    {t('Sitemap:Demo')}
                </a>
            </li>
        ) : null;
    }

    private renderUserMenu() {
        const { isAuthenticated, isGuest, t } = this.props;
        return isAuthenticated && !isGuest ? (
            <li className="nav-item">
                <a
                    href={RoutePaths.Events.List.url()}
                    className="nav-link"
                >
                    {t('Sitemap:Dinner')}
                </a>
            </li>
        ) : null;
    }
}

export const Menu = withTranslation()(MenuComponent);
