import { WebStorageStateStore, InMemoryWebStorage, UserManagerSettings } from 'oidc-client';
import { createUserManager } from 'redux-oidc';
import { getBaseUrl, getConfiguration } from 'config/constants';
import { windowIfDefined } from 'services/WindowService';

const userManagerConfig: UserManagerSettings = {
  authority: getConfiguration().externalLinks.mesHotesAccount,
  client_id: 'spa-meshotes-maps',
  scope: 'openid profile email api.meshotes.com',
  response_type: 'token id_token',
  redirect_uri: `${getBaseUrl()}/callback`,
  silent_redirect_uri: `${getBaseUrl()}/silent_renew.html`,
  includeIdTokenInSilentRenew: false,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true,
  stateStore: process.env.NODE_ENV === 'test' || !windowIfDefined ? new WebStorageStateStore({ store: new InMemoryWebStorage() }) : undefined,
  userStore: process.env.NODE_ENV === 'test' || !windowIfDefined ? new WebStorageStateStore({ store: new InMemoryWebStorage() }) : undefined,
  monitorSession: false,
};

export const userManager = createUserManager(userManagerConfig);
