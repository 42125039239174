import { documentIfDefined } from './WindowService';

const getCookie = (cname: string) => {
    const name = cname + '=';
    const decodedCookie = documentIfDefined ? decodeURIComponent(documentIfDefined.cookie) : '';
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
}

export const getXsrfToken = () => {
    return getCookie('XSRF-TOKEN');
};