export const createInitSignInAction = () => {
    return {
        type: '@AUTHENTICATION/INIT_SIGN_IN',
    } as const;
};

export const createInitSignInCompleteAction = () => {
    return {
        type: '@AUTHENTICATION/INIT_SIGN_IN_COMPLETE',
    } as const;
};

export const createInitSignOutAction = () => {
    return {
        type: '@AUTHENTICATION/INIT_SIGN_OUT',
    } as const;
};

export type AuthenticationAction =
    | ReturnType<typeof createInitSignInAction>
    | ReturnType<typeof createInitSignInCompleteAction>
    | ReturnType<typeof createInitSignOutAction>;
