import { forOwn } from 'lodash-es';
import { AppReducer } from 'store';
import { IEntities } from 'services/api/ApiSchema';

const unloadedState = () => {
    return {
    } as const;
};

export const NormalizrReducer: AppReducer<IEntities> = (state = unloadedState(), action) => {
    let newState: Record<string, Record<string, unknown> | undefined>;
    switch (action.type) {
        case '@NORMALIZR/MERGE_ENTITIES':
            newState = { ...state };
            forOwn(action.payload, (value, key) => {
                const entities = newState[key];
                if (entities) {
                    forOwn(value, (entityValue, entityKey) => {
                        entities[entityKey] = entityValue;
                    });
                } else {
                    if (value) {
                        newState[key] = value;
                    } else {
                        delete newState[key];
                    }
                }
            });
            return newState;

        case '@NORMALIZR/REMOVE_ENTITIES':
            newState = { ...state };
            forOwn(action.payload, (value, key) => {
                const entities = newState[key];
                if (entities) {
                    forOwn(value, (_, entityKey) => {
                        if (entityKey in entities) {
                            delete entities[entityKey];
                        }
                    });
                }
            });
            return newState;

        default:
            return state;
    }
};
