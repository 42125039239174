import i18n from 'i18next';
import { compile } from 'path-to-regexp';
import { getConfiguration } from 'config/constants';

export const RoutePaths = {
    About: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:About', lng)}`,
        url: () => RoutePaths.url(RoutePaths.About.route),
    },
    Account: {
        Login: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:Login', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.Login.route),
        },
        Register: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:Register', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.Register.route),
        },
        route: () => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}`,
    },
    Blog: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotes}${RoutePaths.route()}/${t('Routing:Blog', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Blog.route),
    },
    Callback: {
        route: (lng: string) => `/${t('Routing:Callback', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Callback.route),
    },
    Contact: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:Contact', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Contact.route),
    },
    Errors: {
        NotFound: {
            route: () => {
                return '*';
            },
            url: () => RoutePaths.url(RoutePaths.Errors.NotFound.route),
        },
    },
    Events: {
        Create: {
            route: (lng: string) => `${RoutePaths.Events.route(lng)}/${t('Routing:Create', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Events.Create.route),
        },
        List: {
            route: (lng: string) => `${RoutePaths.Events.route(lng)}/:eventId(\\d+)?`,
            url: (eventId?: number, customUrl?: string, demoId?: string) => RoutePaths.url(RoutePaths.Events.List.route, { eventId, customUrl, demoId }),
        },
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotes}${RoutePaths.route()}/${t('Routing:Events', lng)}`,
    },
    Forum: {
        route: () => `${getConfiguration().externalLinks.mesHotesForum}`,
        url: () => RoutePaths.url(RoutePaths.Forum.route),
    },
    Help: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:Help', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Help.route),
    },
    Legal: {
        LegalNotice: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:LegalNotices', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.LegalNotice.route),
        },
        Privacy: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:Privacy', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.Privacy.route),
        },
        TermsOfSales: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:TermsOfSales', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfSales.route),
        },
        TermsOfSalesPro: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:Pro', lng)}/${t('Routing:TermsOfSales', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfSalesPro.route),
        },
        TermsOfUse: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:TermsOfUse', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfUse.route),
        },
        TermsOfUsePro: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:Pro', lng)}/${t('Routing:TermsOfUse', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfUsePro.route),
        },
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:Legal', lng)}`,
    },
    Manage: {
        EmailSubscription: {
            route: (lng: string) => `${RoutePaths.Manage.route()}/${t('Routing:Manage', lng)}/${t('Routing:EmailSubscription', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.EmailSubscription.route),
        },
        MyPurchases: {
            route: (lng: string) => `${RoutePaths.Manage.route()}/${t('Routing:Manage', lng)}/${t('Routing:MyPurchases', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.MyPurchases.route),
        },
        PaymentInformation: {
            route: (lng: string) => `${RoutePaths.Manage.route()}/${t('Routing:Manage', lng)}/${t('Routing:PaymentInformation', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.PaymentInformation.route),
        },
        Profile: {
            route: () => `${RoutePaths.Manage.route()}`,
            url: () => RoutePaths.url(RoutePaths.Manage.Profile.route),
        },
        route: () => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}`,
        url: () => RoutePaths.url(RoutePaths.Manage.route),
    },
    Press: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:Press', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Press.route),
    },
    Maps: {
        Directory: {
            Pro: {
                Caterer: {
                    route: (lng: string) => `${RoutePaths.Maps.route()}/${t('Routing:Pro', lng)}/${t('Routing:Caterer', lng)}/:country/:city/:name/:id(\\d+)`,
                    url: (country: string, city: string, name: string, id: number) => RoutePaths.url(RoutePaths.Maps.Directory.Pro.Caterer.route, { country, city, name, id }),
                },
                Domain: {
                    route: (lng: string) => `${RoutePaths.Maps.route()}/${t('Routing:Pro', lng)}/${t('Routing:Domain', lng)}/:country/:city/:name/:id(\\d+)`,
                    url: (country: string, city: string, name: string, id: number) => RoutePaths.url(RoutePaths.Maps.Directory.Pro.Domain.route, { country, city, name, id }),
                },
                WeddingPlanner: {
                    route: (lng: string) => `${RoutePaths.Maps.route()}/${t('Routing:Pro', lng)}/${t('Routing:WeddingPlanner', lng)}/:country/:city/:name/:id(\\d+)`,
                    url: (country: string, city: string, name: string, id: number) => RoutePaths.url(RoutePaths.Maps.Directory.Pro.WeddingPlanner.route, { country, city, name, id }),
                },
                route: (lng: string) => `${RoutePaths.Maps.route()}/${t('Routing:Pro', lng)}/:featureType/:country/:city/:name/:id(\\d+)`,
                url: (featureType: string, country: string, city: string, name: string, id: number) => RoutePaths.url(RoutePaths.Maps.Directory.Pro.route, { featureType, country, city, name, id }),
            },
            route: (lng: string) => `${RoutePaths.Maps.route()}/${t('Routing:Pro', lng)}/:featureType/:country?/:region?/:county?`,
            url: (featureType: string, country?: string, region?: string, county?: string) => RoutePaths.url(RoutePaths.Maps.Directory.route, { featureType, country, region, county }),
        },
        Map: {
            route: () => `${RoutePaths.route()}/:mapCenter?`,
            url: (mapCenter?: string) => RoutePaths.url(RoutePaths.Maps.Map.route, { mapCenter }),
        },
        MapPro: {
            route: () => `${RoutePaths.route()}/:featureType/:country/:city/:name/:id(\\d+)/:mapCenter?`,
            url: (featureType: string, country: string, city: string, name: string, id: number, mapCenter?: string) => RoutePaths.url(RoutePaths.Maps.MapPro.route, { mapCenter, featureType, country, city, name, id }),
        },
        route: () => `${RoutePaths.route()}`,
        url: () => RoutePaths.url(RoutePaths.Maps.route),
    },
    ServicesPricing: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:ServicesPricing', lng)}`,
        url: () => RoutePaths.url(RoutePaths.ServicesPricing.route),
    },

    route: () => {
        return '/:lang([a-zA-Z]{2})';
    },

    url: (route: (lng: string) => string, parameters?: { [id: string]: unknown }) => {
        const { language } = i18n;
        return RoutePaths.urlForLang(language, route, parameters).toLowerCase();
    },

    urlForLang: (language: string, route: (lng: string) => string, parameters?: { [id: string]: unknown }) => {
        let localizedRoute = route(language);
        const routingEvent: string = i18n.t('Routing:Events', { lng: language });
        if (parameters?.demoId) {
            localizedRoute = localizedRoute.replace(`/${routingEvent}/:eventId(\\d+)`, `/demo/:demoId`);
        }
        else if (parameters?.customUrl) {
            localizedRoute = localizedRoute.replace(`/${routingEvent}/:eventId(\\d+)`, `/public/:customUrl`);
        }

        if (localizedRoute.startsWith('http')) {
            localizedRoute = localizedRoute.replace(/(https?):\/\/([a-z.]*):?([\d]+)/i, '$1\\://$2\\:$3');
            localizedRoute = localizedRoute.replace(/(https?):\/\//, '$1\\://');
        }

        if (parameters) {
            for (const key in parameters) {
                if (Object.prototype.hasOwnProperty.call(parameters, key)) {
                    const value = parameters[key];
                    if (typeof value === 'string') {
                        parameters[key] = RoutePaths.getRouteString(value);
                    }
                }
            }
        }

        return compile(localizedRoute)({
            lang: language,
            ...parameters,
        });
    },

    getRouteString: (value: string | undefined): string | undefined => {
        return value?.replace(/\s/g, '-').toLowerCase();
    },
};

const t = (key: string, lng: string) => i18n.t(key, { lng });
