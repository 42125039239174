import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

const unloadedState = () => {
    return {
        catererDetailsRequests: {} as { [featureId: number]: IRequest },
        domainDetailsRequests: {} as { [featureId: number]: IRequest },
        weddingPlannerDetailsRequests: {} as { [featureId: number]: IRequest },
    } as const;
};

export type ProsState = ReturnType<typeof unloadedState>;

export const ProsReducer: AppReducer<ProsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@PROS/GET_CATERER_DETAILS_REQUEST':
            return {
                ...state,
                catererDetailsRequests: {
                    ...state.catererDetailsRequests,
                    [action.catererId]: {
                        ...state.catererDetailsRequests[action.catererId],
                        didInvalidate: state.catererDetailsRequests[action.catererId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@PROS/GET_CATERER_DETAILS_SUCCESS':
            return {
                ...state,
                catererDetailsRequests: {
                    ...state.catererDetailsRequests,
                    [action.catererId]: {
                        ...state.catererDetailsRequests[action.catererId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@PROS/GET_CATERER_DETAILS_FAILURE':
            return {
                ...state,
                catererDetailsRequests: {
                    ...state.catererDetailsRequests,
                    [action.catererId]: {
                        ...state.catererDetailsRequests[action.catererId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@PROS/GET_DOMAIN_DETAILS_REQUEST':
            return {
                ...state,
                domainDetailsRequests: {
                    ...state.domainDetailsRequests,
                    [action.proDomainId]: {
                        ...state.domainDetailsRequests[action.proDomainId],
                        didInvalidate: state.domainDetailsRequests[action.proDomainId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@PROS/GET_DOMAIN_DETAILS_SUCCESS':
            return {
                ...state,
                domainDetailsRequests: {
                    ...state.domainDetailsRequests,
                    [action.proDomainId]: {
                        ...state.domainDetailsRequests[action.proDomainId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@PROS/GET_DOMAIN_DETAILS_FAILURE':
            return {
                ...state,
                domainDetailsRequests: {
                    ...state.domainDetailsRequests,
                    [action.proDomainId]: {
                        ...state.domainDetailsRequests[action.proDomainId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@PROS/GET_WEDDING_PLANNER_DETAILS_REQUEST':
            return {
                ...state,
                weddingPlannerDetailsRequests: {
                    ...state.weddingPlannerDetailsRequests,
                    [action.weddingPlannerId]: {
                        ...state.weddingPlannerDetailsRequests[action.weddingPlannerId],
                        didInvalidate: state.weddingPlannerDetailsRequests[action.weddingPlannerId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@PROS/GET_WEDDING_PLANNER_DETAILS_SUCCESS':
            return {
                ...state,
                weddingPlannerDetailsRequests: {
                    ...state.weddingPlannerDetailsRequests,
                    [action.weddingPlannerId]: {
                        ...state.weddingPlannerDetailsRequests[action.weddingPlannerId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@PROS/GET_WEDDING_PLANNER_DETAILS_FAILURE':
            return {
                ...state,
                weddingPlannerDetailsRequests: {
                    ...state.weddingPlannerDetailsRequests,
                    [action.weddingPlannerId]: {
                        ...state.weddingPlannerDetailsRequests[action.weddingPlannerId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
