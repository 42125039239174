import { RouterState, RouterAction } from 'connected-react-router';
import { Action as ReduxAction, Reducer } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
    reducer as OidcReducer,
    USER_EXPIRED,
    REDIRECT_SUCCESS,
    USER_LOADED,
    SILENT_RENEW_ERROR,
    SESSION_TERMINATED,
    USER_EXPIRING,
    USER_FOUND,
    LOADING_USER,
    LOAD_USER_ERROR,
    USER_SIGNED_OUT,
} from 'redux-oidc';

import { AppContextAction } from './appContext/actions';
import { AppContextReducer } from './appContext/reducer';
import { AuthenticationAction } from './authentication/actions';
import { AuthenticationReducer } from './authentication/reducer';
import { DirectoriesAction } from './directories/actions';
import { DirectoriesReducer } from './directories/reducer';
import { FeaturesAction } from './features/actions';
import { FeaturesReducer } from './features/reducer';
import { GeolocationAction } from './geolocation/actions';
import { GeolocationReducer } from './geolocation/reducer';
import { NormalizrAction } from './normalizr/actions';
import { NormalizrReducer } from './normalizr/reducer';
import { ProsAction } from './pros/actions';
import { ProsReducer } from './pros/reducer';

export const reducers = {
    appContext: AppContextReducer,
    authentication: AuthenticationReducer,
    directories: DirectoriesReducer,
    entities: NormalizrReducer,
    features: FeaturesReducer,
    geolocation: GeolocationReducer,
    oidc: OidcReducer,
    pros: ProsReducer,
};

type ReducersMapObject = typeof reducers;
type StateKeys = keyof ReducersMapObject;
export type ApplicationState = { readonly [key in StateKeys]: ReturnType<ReducersMapObject[key]> } & { router: RouterState };

const reduxOidcActionTypes = {
    'redux-oidc/REDIRECT_SUCCESS': REDIRECT_SUCCESS,
    'redux-oidc/USER_LOADED': USER_LOADED,
    'redux-oidc/SILENT_RENEW_ERROR': SILENT_RENEW_ERROR,
    'redux-oidc/SESSION_TERMINATED': SESSION_TERMINATED,
    'redux-oidc/USER_EXPIRING': USER_EXPIRING,
    'redux-oidc/USER_EXPIRED': USER_EXPIRED,
    'redux-oidc/USER_FOUND': USER_FOUND,
    'redux-oidc/LOADING_USER': LOADING_USER,
    'redux-oidc/LOAD_USER_ERROR': LOAD_USER_ERROR,
    'redux-oidc/USER_SIGNED_OUT': USER_SIGNED_OUT,
};
type ReduxOidcAction = { type: keyof typeof reduxOidcActionTypes };

export type Action =
    | AppContextAction
    | AuthenticationAction
    | NormalizrAction
    | DirectoriesAction
    | FeaturesAction
    | GeolocationAction
    | NormalizrAction
    | ProsAction
    | ReduxOidcAction
    | RouterAction;

export type AppReducer<S> = Reducer<S, Action>;
export type AppThunkAction<R = void> = ThunkAction<Promise<R>, ApplicationState, unknown, Action | ReduxAction>;
export type AppThunkDispatch = ThunkDispatch<ApplicationState, unknown, Action | ReduxAction>;
