import { apiVersion } from 'config/constants';
import { Guid } from 'guid-typescript';
import i18n from 'i18next';
import { isString } from 'lodash-es';
import { toastr } from 'react-redux-toastr';
import { getAuthInfo, signIn } from 'services/AuthService';
import { SSRService } from 'services/ServerSideRenderingService';
import { updateServiceWorker } from 'services/ServiceWorkerService';
import { getXsrfToken } from 'services/XsrfService';
import { RequestOptions, Agent } from 'https';

export interface IOnGoingRequest {
    requestId: Guid;
    requestInfo: RequestInfo;
    controller?: AbortController;
}

export interface IOnGoingRequests {
    [requestId: string]: IOnGoingRequest;
}

export class Http {
    public static OnGoingRequests: IOnGoingRequests = {};
    public static ReCaptchaToken?: string = undefined;

    public static async fetch(requestInfo: RequestInfo, init?: RequestInit): Promise<Response> {
        const headers = new Headers();
        headers.append('Accept', 'application/json');

        const authInfo = await getAuthInfo();
        if (authInfo && authInfo.accessToken) {
            headers.append('Authorization', `Bearer ${authInfo.accessToken}`);
        }

        const xsrfToken = getXsrfToken();
        if (xsrfToken) {
            headers.append('X-XSRF-TOKEN', xsrfToken);
        }

        if (this.ReCaptchaToken) {
            headers.append('g-recaptcha-response', this.ReCaptchaToken);
        }

        if (init && init.headers) {
            new Headers(init.headers).forEach((value: string, key: string) => {
                headers.append(key, value);
            });
        }

        const requestInit = init || {};
        requestInit.credentials = 'include';
        requestInit.headers = headers;

        const isNode = typeof window === 'undefined';
        if (isNode) {
            (requestInit as RequestOptions).agent = new Agent({ rejectUnauthorized: false });
        }

        let requestId: Guid;
        if (typeof AbortController !== 'undefined') {
            const controller = new AbortController();
            const signal = controller.signal;
            requestInit.signal = signal;
            requestId = this.addOngoingRequest(requestInfo, controller);
        } else {
            requestId = this.addOngoingRequest(requestInfo);
        }

        return SSRService.fetch(requestInfo, requestInit)
            .then((response: Response) => {
                if (!this.OnGoingRequests[requestId.toString()]) {
                    const abortError = new Error('The user aborted a request.');
                    abortError.name = 'AbortError';
                    throw abortError;
                }

                this.removeOngoingRequest(requestId);
                this.checkVersion(response);
                if (response) {
                    if (response.status === 401) {
                        if (requestInit.method === "GET") {
                            signIn();
                        } else {
                            Http.displayError(response, i18n.t('Common:UnableToSave'));
                        }
                    } else if (response.status === 403) {
                        toastr.error(i18n.t('Common:Error'), i18n.t('Common:Access_denied'));
                    } else if (response.status >= 400) {
                        Http.displayError(response, i18n.t('Common:PageServerError'));
                    }
                }
                return response;
            })
            .catch((err) => {
                this.removeOngoingRequest(requestId);
                throw err;
            });
    }

    private static displayError(response: Response, defaultErrorMessage: string) {
        /*clone() prevents locking the response*/
        response
            .clone()
            .text()
            .then((text) => {
                if (!text) {
                    toastr.error(i18n.t('Common:Error'), defaultErrorMessage);
                }
                else {
                    try {
                        const error = JSON.parse(text);
                        const modelStateError = error as { errors: { [key: string]: string[] }, title: string };
                        if (modelStateError.errors) {
                            const errors = Object.entries(modelStateError.errors)
                                .map(([key, errors]) => `${key === '$' ? '' : key + ': '}${errors.join('\n')}`)
                                .join('\n');
                            const message = `${modelStateError.title}\n${errors}`;
                            toastr.error(i18n.t('Common:Error'), message);
                        }
                        else {
                            toastr.error(i18n.t('Common:Error'), defaultErrorMessage);
                        }
                    }
                    catch (_) {
                        toastr.error(i18n.t('Common:Error'), defaultErrorMessage);
                    }
                }
            })
            .catch(() => {
                toastr.error(i18n.t('Common:Error'), defaultErrorMessage);
            });
    }

    public static abortRequest(requestId: Guid) {
        const request = this.OnGoingRequests[requestId.toString()];
        if (request && request.controller) {
            request.controller.abort();
        }
        this.removeOngoingRequest(request.requestId);
    }

    public static abortRequests(urlMatcher: string | RegExp) {
        for (const requestId in this.OnGoingRequests) {
            if (Object.prototype.hasOwnProperty.call(this.OnGoingRequests, requestId)) {
                const request = this.OnGoingRequests[requestId];
                const url = isString(request.requestInfo) ? request.requestInfo : request.requestInfo.url;
                if (isString(urlMatcher)) {
                    if (url === urlMatcher) {
                        this.abortRequest(request.requestId);
                    }
                } else if (url.search(urlMatcher) >= 0) {
                    this.abortRequest(request.requestId);
                }
            }
        }
    }

    private static addOngoingRequest(requestInfo: RequestInfo, controller?: AbortController) {
        const requestId = Guid.create();

        this.OnGoingRequests[requestId.toString()] = {
            requestId,
            requestInfo,
            controller,
        };

        return requestId;
    }

    private static removeOngoingRequest(requestId: Guid) {
        delete this.OnGoingRequests[requestId.toString()];
    }

    private static checkVersion(response: Response) {
        const serverApiVersion = response.headers.get('api-version');
        const newVersionReleased = serverApiVersion && serverApiVersion !== apiVersion;
        const isNode = typeof window === 'undefined';
        if (!isNode && newVersionReleased) {
            updateServiceWorker().catch(() => {
                toastr.warning(
                    i18n.t('Common:NewVersionAvailable'),
                    i18n.t('Common:NewVersionAvailable_Message'),
                    {
                        timeOut: 0,
                    });
            });
        }
    }
}
