import { AppReducer } from 'store';

const unloadedState = () => {
    return {
        didInvalidate: true as boolean,
        isFetching: false as boolean,
        appContextId: '' as string,
        isChangingLanguage: false as boolean,
        isCookieConsentAnswering: false as boolean,
    } as const;
};

export type AppContextState = ReturnType<typeof unloadedState>;

export const AppContextReducer: AppReducer<AppContextState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@APP_CONTEXT/GET_APP_CONTEXT_REQUEST':
            return {
                ...state,
                isFetching: true,
            };

        case '@APP_CONTEXT/GET_APP_CONTEXT_SUCCESS':
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                appContextId: action.appContextId,
            };

        case '@APP_CONTEXT/GET_APP_CONTEXT_FAILURE':
            return {
                ...state,
                isFetching: false,
                didInvalidate: true,
            };

        case '@APP_CONTEXT/GET_APP_CONTEXT_INVALIDATION':
        case 'redux-oidc/USER_LOADED':
        case 'redux-oidc/USER_EXPIRED':
        case 'redux-oidc/USER_SIGNED_OUT':
        case 'redux-oidc/USER_FOUND':
            return {
                ...state,
                didInvalidate: true,
            };

        case '@APP_CONTEXT/CONSENT_COOKIE_REQUEST':
            return {
                ...state,
                isCookieConsentAnswering: true,
            };

        case '@APP_CONTEXT/CONSENT_COOKIE_SUCCESS':
            return {
                ...state,
                isCookieConsentAnswering: false,
                didInvalidate: true,
            };

        case '@APP_CONTEXT/CONSENT_COOKIE_FAILURE':
            return {
                ...state,
                isCookieConsentAnswering: false,
            };

        case '@APP_CONTEXT/CHANGE_LANGUAGE_REQUEST':
            return {
                ...state,
                isChangingLanguage: true,
            };

        case '@APP_CONTEXT/CHANGE_LANGUAGE_SUCCESS':
            return {
                ...state,
                isChangingLanguage: false,
            };

        case '@APP_CONTEXT/CHANGE_LANGUAGE_FAILURE':
            return {
                ...state,
                isChangingLanguage: false,
            };

        default:
            return state;
    }
};
