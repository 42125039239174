import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export const DEFAULT_TAKE_SIZE = 20;

export interface ICountriesRequest extends IRequest {
    readonly countries: string[];
}

export interface IRegionsRequest extends IRequest {
    readonly regions: string[];
}

export interface ICountiesRequest extends IRequest {
    readonly counties: string[];
}

export interface IFeaturesRequest extends IRequest {
    readonly features: string[];
    readonly totalFeatures: number;
}


const unloadedState = () => {
    return {
        countriesRequests: {} as { [featureType: string]: ICountriesRequest },
        regionsRequests: {} as { [featureType: string]: { [countryCode: string]: IRegionsRequest } },
        countiesRequests: {} as { [featureType: string]: { [countryCode: string]: { [region: string]: ICountiesRequest } } },
        featuresRequests: {} as { [featureType: string]: { [countryCode: string]: { [region: string]: { [county: string]: IFeaturesRequest } } } },
    } as const;
};

export type DirectoriesState = ReturnType<typeof unloadedState>;

export const DirectoriesReducer: AppReducer<DirectoriesState> = (state = unloadedState(), action) => {
    let regionsRequests: Record<string, IRegionsRequest>;
    let countiesRequests: Record<string, Record<string, ICountiesRequest>>;
    let countiesRequestsByCountry: Record<string, ICountiesRequest>;
    let featuresRequests: Record<string, Record<string, Record<string, IFeaturesRequest>>>;
    let featuresRequestsByCountry: Record<string, Record<string, IFeaturesRequest>>;
    let featuresRequestsByRegion: Record<string, IFeaturesRequest>;

    switch (action.type) {
        case '@DIRECTORY/GET_COUNTRIES_REQUEST':
            return {
                ...state,
                countriesRequests: {
                    ...state.countriesRequests,
                    [action.featureType]: {
                        ...state.countriesRequests[action.featureType],
                        didInvalidate: state.countriesRequests[action.featureType]?.didInvalidate || false,
                        countries: state.countriesRequests[action.featureType]?.countries || [],
                        isFetching: true,
                    },
                },
            };

        case '@DIRECTORY/GET_COUNTRIES_SUCCESS':
            return {
                ...state,
                countriesRequests: {
                    ...state.countriesRequests,
                    [action.featureType]: {
                        ...state.countriesRequests[action.featureType],
                        isFetching: false,
                        didInvalidate: false,
                        countries: action.countries,
                    },
                },
            };

        case '@DIRECTORY/GET_COUNTRIES_FAILURE':
            return {
                ...state,
                countriesRequests: {
                    ...state.countriesRequests,
                    [action.featureType]: {
                        ...state.countriesRequests[action.featureType],
                        countries: state.countriesRequests[action.featureType]?.countries || [],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@DIRECTORY/GET_REGIONS_REQUEST':
            regionsRequests = state.regionsRequests[action.featureType] || {};
            return {
                ...state,
                regionsRequests: {
                    ...state.regionsRequests,
                    [action.featureType]: {
                        ...regionsRequests,
                        [action.countryCode]: {
                            ...regionsRequests[action.countryCode],
                            regions: regionsRequests[action.countryCode]?.regions || [],
                            didInvalidate: regionsRequests[action.countryCode]?.didInvalidate || false,
                            isFetching: true,
                        },
                    },
                },
            };

        case '@DIRECTORY/GET_REGIONS_SUCCESS':
            regionsRequests = state.regionsRequests[action.featureType] || {};
            return {
                ...state,
                regionsRequests: {
                    ...state.regionsRequests,
                    [action.featureType]: {
                        ...regionsRequests,
                        [action.countryCode]: {
                            ...regionsRequests[action.countryCode],
                            isFetching: false,
                            didInvalidate: false,
                            regions: action.regions,
                        },
                    },
                },
            };

        case '@DIRECTORY/GET_REGIONS_FAILURE':
            regionsRequests = state.regionsRequests[action.featureType] || {};
            return {
                ...state,
                regionsRequests: {
                    ...state.regionsRequests,
                    [action.featureType]: {
                        ...regionsRequests,
                        [action.countryCode]: {
                            ...regionsRequests[action.countryCode],
                            isFetching: false,
                            didInvalidate: true,
                            regions: regionsRequests[action.countryCode]?.regions || [],
                        },
                    },
                },
            };

        case '@DIRECTORY/GET_COUNTIES_REQUEST':
            countiesRequests = state.countiesRequests[action.featureType] || {};
            countiesRequestsByCountry = countiesRequests[action.countryCode] || {};
            return {
                ...state,
                countiesRequests: {
                    ...state.countiesRequests,
                    [action.featureType]: {
                        ...countiesRequests,
                        [action.countryCode]: {
                            ...countiesRequestsByCountry,
                            [action.region]: {
                                ...countiesRequestsByCountry[action.region],
                                isFetching: true,
                                didInvalidate: countiesRequestsByCountry[action.region]?.didInvalidate || false,
                                counties: countiesRequestsByCountry[action.region]?.counties || [],
                            },
                        },
                    },
                },
            };

        case '@DIRECTORY/GET_COUNTIES_SUCCESS':
            countiesRequests = state.countiesRequests[action.featureType] || {};
            countiesRequestsByCountry = countiesRequests[action.countryCode] || {};
            return {
                ...state,
                countiesRequests: {
                    ...state.countiesRequests,
                    [action.featureType]: {
                        ...countiesRequests,
                        [action.countryCode]: {
                            ...countiesRequestsByCountry,
                            [action.region]: {
                                ...countiesRequestsByCountry[action.region],
                                isFetching: false,
                                didInvalidate: false,
                                counties: action.counties,
                            },
                        },
                    },
                },
            };

        case '@DIRECTORY/GET_COUNTIES_FAILURE':
            countiesRequests = state.countiesRequests[action.featureType] || {};
            countiesRequestsByCountry = countiesRequests[action.countryCode] || {};
            return {
                ...state,
                countiesRequests: {
                    ...state.countiesRequests,
                    [action.featureType]: {
                        ...countiesRequests,
                        [action.countryCode]: {
                            ...countiesRequestsByCountry,
                            [action.region]: {
                                ...countiesRequestsByCountry[action.region],
                                isFetching: false,
                                didInvalidate: true,
                                counties: countiesRequestsByCountry[action.region]?.counties || [],
                            },
                        },
                    },
                },
            };

        case '@DIRECTORY/GET_INDEXED_FEATURES_REQUEST':
            featuresRequests = state.featuresRequests[action.featureType] || {};
            featuresRequestsByCountry = featuresRequests[action.countryCode] || {};
            featuresRequestsByRegion = featuresRequestsByCountry[action.region] || {};
            return {
                ...state,
                featuresRequests: {
                    ...state.featuresRequests,
                    [action.featureType]: {
                        ...featuresRequests,
                        [action.countryCode]: {
                            ...featuresRequestsByCountry,
                            [action.region]: {
                                ...featuresRequestsByRegion,
                                [action.county]: {
                                    ...featuresRequestsByRegion[action.county],
                                    isFetching: true,
                                    didInvalidate: featuresRequestsByRegion[action.county]?.didInvalidate || false,
                                    features: featuresRequestsByRegion[action.county]?.features || [],
                                    totalFeatures: featuresRequestsByRegion[action.county]?.totalFeatures || 0,
                                },
                            },
                        },
                    },
                },
            };

        case '@DIRECTORY/GET_INDEXED_FEATURES_SUCCESS':
            featuresRequests = state.featuresRequests[action.featureType] || {};
            featuresRequestsByCountry = featuresRequests[action.countryCode] || {};
            featuresRequestsByRegion = featuresRequestsByCountry[action.region] || {};
            return {
                ...state,
                featuresRequests: {
                    ...state.featuresRequests,
                    [action.featureType]: {
                        ...featuresRequests,
                        [action.countryCode]: {
                            ...featuresRequestsByCountry,
                            [action.region]: {
                                ...featuresRequestsByRegion,
                                [action.county]: {
                                    ...featuresRequestsByRegion[action.county],
                                    isFetching: false,
                                    didInvalidate: false,
                                    features: action.features,
                                    totalFeatures: action.totalFeatures,
                                },
                            },
                        },
                    },
                },
            };

        case '@DIRECTORY/GET_INDEXED_FEATURES_FAILURE':
            featuresRequests = state.featuresRequests[action.featureType] || {};
            featuresRequestsByCountry = featuresRequests[action.countryCode] || {};
            featuresRequestsByRegion = featuresRequestsByCountry[action.region] || {};
            return {
                ...state,
                featuresRequests: {
                    ...state.featuresRequests,
                    [action.featureType]: {
                        ...featuresRequests,
                        [action.countryCode]: {
                            ...featuresRequestsByCountry,
                            [action.region]: {
                                ...featuresRequestsByRegion,
                                [action.county]: {
                                    ...featuresRequestsByRegion[action.county],
                                    isFetching: false,
                                    didInvalidate: true,
                                    features: featuresRequestsByRegion[action.county]?.features || [],
                                    totalFeatures: featuresRequestsByRegion[action.county]?.totalFeatures || 0,
                                },
                            },
                        },
                    },
                },
            };

        case '@DIRECTORY/CHANGE_PAGE':
            featuresRequests = state.featuresRequests[action.featureType] || {};
            featuresRequestsByCountry = featuresRequests[action.countryCode] || {};
            featuresRequestsByRegion = featuresRequestsByCountry[action.region] || {};
            return {
                ...state,
                featuresRequests: {
                    ...state.featuresRequests,
                    [action.featureType]: {
                        ...featuresRequests,
                        [action.countryCode]: {
                            ...featuresRequestsByCountry,
                            [action.region]: {
                                ...featuresRequestsByRegion,
                                [action.county]: {
                                    ...featuresRequestsByRegion[action.county],
                                    isFetching: featuresRequestsByRegion[action.county]?.isFetching || false,
                                    didInvalidate: true,
                                    features: featuresRequestsByRegion[action.county]?.features || [],
                                    totalFeatures: featuresRequestsByRegion[action.county]?.totalFeatures || 0,
                                },
                            },
                        },
                    },
                },
            };

        default:
            return state;
    }
};
