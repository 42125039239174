import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IMetaDataProps } from './MetaDataModels';
import i18n from 'i18next';

import logo from 'assets/images/logos/logo-opengraph.jpg';

class MetaDataComponent extends React.PureComponent<IMetaDataProps & WithTranslation> {
    public componentDidMount() {
        const { onInit } = this.props;
        onInit();
    }

    public render() {
        const { t, facebookAppId } = this.props;
        const title = t("Common:Meta_Title");
        const description = t("Common:Meta_Description");
        return (
            <div>
                <Helmet>
                    <html lang={i18n.language} />
                    <title>{title}</title>
                    <meta name="description" content={description} key="description" />
                    <meta name="keywords" content={t("Common:Meta_Keywords")} key="keywords"/>
                    
                    <meta property="og:title" content={title} key="og-title" />
                    <meta property="og:description" content={description} key="og-description" />
                    <meta property="og:image" content={logo} key="og-image" />
                    <meta property="og:site_name" content="Mes Hôtes" key="og-site-name" />
                    <meta property="og:type" content="website" key="og-type" />

                    <meta property="fb:admins" content="581298595,784817700,784402514" key="fb-admins" />
                    {facebookAppId && <meta property="fb:app_id" content={facebookAppId} key="fb-appid" />}

                    <meta name="twitter:card" content="summary" />

                    <link rel="apple-touch-icon" sizes="120x120" href="/icons/ios/icon_120x120.png" key="apple-touch-icon-120" />
                    <link rel="apple-touch-icon" sizes="152x152" href="/icons/ios/icon_152x152.png" key="apple-touch-icon-152" />
                    <link rel="apple-touch-icon" sizes="167x167" href="/icons/ios/icon_167x167.png" key="apple-touch-icon-167" />
                    <link rel="apple-touch-icon" sizes="180x180" href="/icons/ios/icon_180x180.png" key="apple-touch-icon-180" />
                    <link rel="apple-touch-icon" sizes="1024x1024" href="/icons/ios/icon_1024x1024.png" key="apple-touch-icon-1024" />

                    <link rel="icon" type="image/png" sizes="36x36" href="/icons/android/icon_36x36.png" key="icon-36" />
                    <link rel="icon" type="image/png" sizes="48x48" href="/icons/android/icon_48x48.png" key="icon-48" />
                    <link rel="icon" type="image/png" sizes="72x72" href="/icons/android/icon_72x72.png" key="icon-72" />
                    <link rel="icon" type="image/png" sizes="96x96" href="/icons/android/icon_96x96.png" key="icon-96" />
                    <link rel="icon" type="image/png" sizes="192x192" href="/icons/android/icon_192x192.png" key="icon-192" />
                    <link rel="icon" type="image/png" sizes="144x144" href="/icons/android/icon_144x144.png" key="icon-144" />
                    <link rel="icon" type="image/png" sizes="512x512" href="/icons/android/icon_512x512.png" key="icon-512" />

                    <meta name="msapplication-TileColor" content="#ffffff" key="ms-tile-color" />
                    <meta name="msapplication-TileImage" content="/icons/android/icon_144x144.png" key="ms-tile-image" />
                </Helmet>
            </div>
        );
    }
}

export const MetaData = withTranslation()(MetaDataComponent);
