
import { MapFeature } from 'services/api/ApiClient';
import { AppReducer } from 'store';

export const DEFAULT_TAKE_SIZE = 20;

const unloadedState = () => {
    return {
        fetching: [] as string[],
        searchTerms: '' as string,
        minCapacity: 1 as number,
        skip: 0 as number,
        take: DEFAULT_TAKE_SIZE as number,
        availableFeatures: [] as MapFeature[],
        highlightedFeatures: [] as string[],
    } as const;
};

export type FeaturesState = ReturnType<typeof unloadedState>;

export const FeaturesReducer: AppReducer<FeaturesState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@FEATURES/GET_FEATURES_REQUEST':
            return {
                ...state,
                fetching: Array.from(new Set([
                    ...state.fetching,
                    ...action.featureIds,
                ])),
            };

        case '@FEATURES/GET_FEATURES_SUCCESS':
        case '@FEATURES/GET_FEATURES_FAILURE':
            return {
                ...state,
                fetching: state.fetching.filter(id => !action.featureIds.includes(id)),
            };

        case '@FEATURES/SET_AVAILABLE_FEATURES':
            return {
                ...state,
                skip: 0,
                availableFeatures: action.features,
            };

        case '@FEATURES/SET_SEARCH_TERMS':
            return {
                ...state,
                searchTerms: action.searchTerms,
            };

        case '@FEATURES/SET_MIN_CAPACITY':
            return {
                ...state,
                minCapacity: action.minCapacity,
            };

        case '@FEATURES/CHANGE_PAGE':
            return {
                ...state,
                skip: action.skip,
                take: action.take,
            };

        case '@FEATURES/HIGHLIGHT_FEATURE':
            return {
                ...state,
                highlightedFeatures: Array.from(new Set([
                    ...state.highlightedFeatures,
                    action.featureId,
                ])),
            };

        case '@FEATURES/UNHIGHLIGHT_FEATURE':
            return {
                ...state,
                highlightedFeatures: state.highlightedFeatures.filter(id => id !== action.featureId),
            };

        case '@FEATURES/CLEAR_HIGHLIGHTED_FEATURE':
            return {
                ...state,
                highlightedFeatures: [],
            };

        default:
            return state;
    }
};
