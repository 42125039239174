import {

} from './ApiClient';
//import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './ApiSchema';

export class BaseApiSchema {
    // protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
    //     switch (type) {
    //         case ObjectsWithoutIdPropertyEnum.PaymentInformation:
    //             return `${(value as PaymentInformation).userId}` || '-';
    //     }

    //     const exhaustiveCheck: never = type;
    //     return exhaustiveCheck;
    // }
}
