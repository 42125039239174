import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { publicPath } from 'config/constants';
import backend from './backend';

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/pt';

export const configureI18n = () => {
    return i18n
        .use(detector)
        .use(backend)
        .use(initReactI18next)
        .init({
            backend: {
                loadPath: `${publicPath}locales/{{lng}}/translation.json`,
            },
            lng: 'en',
            fallbackLng: 'en',
            react: { wait: true },
            interpolation: {
                escapeValue: false,
            },
        })
        .then(() => {
            moment.locale(i18n.language);
        });
};
