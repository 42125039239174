import loadable from '@loadable/component';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Route, Switch, RouteComponentProps, matchPath, withRouter } from 'react-router-dom';
import { RoutePaths } from './RoutePaths';
import { Spinner } from 'components/common/loaders';

const Callback = loadable(() => import('components/Pages/Callback'), {
    resolveComponent: components => components.Callback,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Directory = loadable(() => import('components/Pages/Directory'), {
    resolveComponent: components => components.Directory,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Caterer = loadable(() => import('components/Pages/Directory/Pros/Caterer'), {
    resolveComponent: components => components.Caterer,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Domain = loadable(() => import('components/Pages/Directory/Pros/Domain'), {
    resolveComponent: components => components.Domain,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const WeddingPlanner = loadable(() => import('components/Pages/Directory/Pros/WeddingPlanner'), {
    resolveComponent: components => components.WeddingPlanner,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Map = loadable(() => import('components/Pages/Map'), {
    resolveComponent: components => components.Map,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const NotFound = loadable(() => import('components/Pages/Errors'), {
    resolveComponent: components => components.NotFound,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});

class LocalizedRoutesComponent extends React.PureComponent<WithTranslation & RouteComponentProps> {
    public render() {
        const { i18n, location } = this.props;
        const match = matchPath<{ lang: string }>(location.pathname, {
            path: '/:lang/*',
        });
        const localesLoading = match && match.params.lang.toLowerCase() !== i18n.language.toLowerCase();
        return localesLoading ? (
            <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center position-relative" style={{ top: 'calc(50vh - 128px)' }} />
        ) :
            (
                <FragmentSupportingSwitch>
                    {this.createLocalizedRoutes(i18n.language)}
                    <Route path="/callback" component={Callback} />
                    <Route path={RoutePaths.Errors.NotFound.route()} component={NotFound} />
                </FragmentSupportingSwitch>
            );
    }

    private createLocalizedRoutes(language: string) {
        return (
            <React.Fragment>
                <Route exact={true} path={RoutePaths.Maps.Directory.route(language)} component={Directory} />
                <Route exact={true} path={RoutePaths.Maps.Directory.Pro.Caterer.route(language)} component={Caterer} />
                <Route exact={true} path={RoutePaths.Maps.Directory.Pro.Domain.route(language)} component={Domain} />
                <Route exact={true} path={RoutePaths.Maps.Directory.Pro.WeddingPlanner.route(language)} component={WeddingPlanner} />
                <Route exact={true} path={['/', RoutePaths.Maps.Map.route(), RoutePaths.Maps.MapPro.route()]} component={Map} />
            </React.Fragment>
        );
    }
}

function FragmentSupportingSwitch({ children }: { children: React.ReactNode }) {
    const flattenedChildren: React.ReactNode[] = [];
    flatten(flattenedChildren, children);
    return (
        <Switch>
            {flattenedChildren}
        </Switch>
    );
}

function flatten(target: React.ReactNode[], children: React.ReactNode) {
    React.Children.forEach(children, (child) => {
        if (React.isValidElement(child)) {
            if (child.type === React.Fragment) {
                flatten(target, (child as { props: { children?: React.ReactNode } }).props.children);
            } else {
                target.push(child);
            }
        }
    });
}

export const LocalizedRoutes = withRouter(withTranslation()(LocalizedRoutesComponent));